<template>
  <ion-card class="ion-no-margin">
    <ion-card-content>
      <slot></slot>
    </ion-card-content>
  </ion-card>
</template>

<script>
  import { IonCard, IonCardContent } from '@ionic/vue'

  export default {
    components: {
      IonCard,
      IonCardContent,
    },
  }
</script>