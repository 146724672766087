import {toastController} from '@ionic/vue'

export const presentToast = async (message, color) => {
const messages: any = typeof message === 'object'
    ? Object.values(message).reduce((acc: any, next: any) => [...acc, ...next], [])
    : [message]

const toast = await toastController
    .create({
        message: messages.join('\n'),
        color,
        position: 'bottom',
        duration: 2000
    })
toast.present()
}
