
  import {checkmark, close, cameraOutline, imageOutline} from 'ionicons/icons'
  import {defineComponent} from 'vue'
  import {modalController} from '@ionic/vue'
  import {Capacitor} from '@capacitor/core'
  import {Camera, CameraResultType, CameraSource} from '@capacitor/camera'
  import {Cropper} from 'vue-advanced-cropper'
  import 'vue-advanced-cropper/dist/style.css'

  export default defineComponent({
    components: {
      Cropper
    },
    props: {
      source: { type: String, default: 'camera' },
    },
    data() {
      return {
        image: null,
        resultImageBlob: '',
        isEditing: false,
        close,
        cameraOutline,
        checkmark,
        imageOutline
      }
    },
    async mounted() {
      await this.cameraInit()
    },
    methods: {
      imageBounds({ cropper  }) {
        return {
          width: cropper.clientWidth,
          height: cropper.clientHeight - 30,
        }
      },
      async cameraInit() {
        this.isEditing = false
        this.resultImageBlob = ""
        try {
          const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            source: this.source == 'camera' ? CameraSource.Camera : CameraSource.Photos,
            resultType: CameraResultType.Base64
          })
          this.resultImageBlob = 'data:image/jpeg;base64,'+image.base64String
          this.isEditing = true
        } catch(err) {
          this.closeModal('cancel')
        }
      },

      cancelShootedPhoto() {
        this.isEditing = false
        this.resultImageBlob = ''
        this.closeModal('cancel')
      },

      closeModal(text = '') {
        const data = this.$refs?.cropper?.getResult()
        if (text === 'cancel' && !data)
          return modalController.dismiss({ text: text })
        modalController.dismiss({ text: text, result: data.canvas.toDataURL() })
      }
    },
    computed: {
      isMobile() {
        const platform = Capacitor.getPlatform()
        return platform !== 'web'
      }
    }
  })
