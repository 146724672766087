<template>
  <div class="item ion-text-center">
    <!-- <div v-if="getBiometricState()" class="percentage-badge">
      {{pin.propability}}%
    </div> -->
    <div v-if="pin.image" class="height">
      <img :src="pin.image?.path" alt="">
    </div>
    <div v-else class="no-image">
      {{initials}}
    </div>
    <p class="ion-text-center ion-no-margin code">
      <span v-if="module != 'collection' && isLoggedIn" class="semaphore" ></span>

      {{pin.title}}

      <span v-if="module != 'collection' && isLoggedIn">
        <span v-if="pin?.in_collection" class="semaphore green" ></span>
        <span v-else class="semaphore red" ></span>
      </span>
      <img v-if="pin.is_puzzle"  class="puzzle" src="./puzzle.png" alt="puzzle">
    </p>
  </div>
</template>

<script >
  import { mapGetters } from 'vuex'

  export default {
    props: ['pin', 'module'],
    computed: {
      ...mapGetters('wAuth', ['isLoggedIn']),

      initials() {
        return this.pin.title.substring(0, 2)
      }

    },
    methods: {
      getBiometricState() {
        if (this.module == 'countries') return false
        return this.$store.getters[`${this.module}/biometricSearch`].isActive
      }
    }
  }
</script>

<style lang="sass" scoped>

.item
  position: relative

  .percentage-badge
    position: absolute
    right: 10px
    top: 0

    padding: 5px 10px
    border-radius: 6px
    background: #0878CA

    font-weight: bold
    color: white

.semaphore
  width: 10px
  height: 10px
  display: inline-block
  border-radius: 50%
.green
  background: #00C85C
.red
  background: red
.no-image
  background: radial-gradient(60.77% 60.77% at 50% 50%, #FFFFFF 0%, #FFFFFF 100%)
  border: 2px solid rgba(189, 189, 189, 0.2)
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
  border-radius: 12px
  width: 80%
  height: 140px
  line-height: 140px
  margin: 5px auto
  font-size: 40px
  color: rgba(189, 189, 189, 0.6)


img
  max-height: 140px

.height
  margin-bottom: 10px
  height: 140px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center

.code
  font-size: 14px
  line-height: 18px
  font-weight: bold
  position: absolute
  bottom: 0
  left: 0
  width: 100%
  .puzzle
    background: black
    padding: 3px
    border-radius: 50%
    position: relative
    top: 5px
    width: 20px
    margin: 0

</style>
