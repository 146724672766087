import wAxios from '@/plugins/w/axios'

export default {
    namespaced: true,
    state() {
        return {
            isLoading: false,
            countries: [],
            pins: [],
            page: 1,
            allDataLoaded: false,
            inThrottle: false,
            needRefresh: false
        }
    },

    getters: {
        countries(state) {
          return state.countries
        },
        pins(state) {
            return state.pins
        },
        isLoading(state) {
            return state.isLoading
        },
        allDataLoaded(state) {
            return state.allDataLoaded
        },
        needRefresh(state) {
            return state.needRefresh
        }

    },

    mutations: {
        needRefreshChanged(state, needRefresh) {
            state.needRefresh = needRefresh
        },
        countriesFetched(state, countries) {
            state.countries = countries
        },
        loadingChanged(state, status) {
            state.isLoading = status
            console.log('loadingChanged', state.isLoading)
        },
        fetched(state, pins) {
            state.pins = pins
        },
        fetchedByPage(state, pins) {
            if (pins.length && pins[0]?.id && state.pins.find(pin => pin.id == pins[0]?.id)) {
                return
            }
            state.pins = state.pins.concat(pins)
        },
        pageIncremented(state) {
            state.page++
        },
        page(state, page) {
            state.page = page
        },
        allDataLoadedChanged(state, data) {
            state.allDataLoaded = data
        },
        totalPinsCount(state, data) {
            state.totalPinsCount = data
        },
        inCollectionChanged(state, data) {
            let pin = state.pins.find(pin => pin.id == data.pinId)
            if (pin) {
                pin['in_collection'] = data.value
            }
        },
        throttleRequest(state) {
            const DELAY = 500
            if (state.inThrottle) {
                return
              }
            
            state.inThrottle = true

            setTimeout(()=> {
                state.inThrottle = false
              }, DELAY)
        }
    },
    actions: {
        changeNeedRefresh({ commit }, payload) {
            console.log('need it', payload)
            commit('needRefreshChanged', payload)
        },
        async fetchCountries({ commit }) {
            try {
                const data = await wAxios.get_auth(`v1/countries`)
                commit('countriesFetched', data)
                commit('loadingChanged', false)

            } catch (err) {
                console.log(err)
                commit('loadingChanged', false)
            }
        },
        async fetchByPage({ getters, commit, state }, payload) {
            commit('throttleRequest')
        
            if (state.isThrottled || state.isLoading) return
            commit('loadingChanged', true)
            if ( payload.page ) {
                commit('page', payload.page)
                commit('fetched', [])
                commit('allDataLoadedChanged', false)
            }
            try {
                const message = `LOG:countries|country:${payload.country}|page:${state.page}`
                try {
                    await wAxios.post(`v1/logger`, {message: message})
                } catch (e) {
                    console.log('ERROR')
                }
                const { data: { data } } = await wAxios.get_auth_all(`v1/countries/${payload.country}/pins?page=${state.page}`).then(
                    commit('pageIncremented')
                )
                commit('fetchedByPage', data)
                commit('loadingChanged', false)
                if (data.length == 0) {
                    commit('allDataLoadedChanged', true)
                }
            } catch (err) {
                console.log(err)
            }
        },
    }
}
