
import { modalController, actionSheetController } from '@ionic/vue'
import Filter from './a-filter-modal.vue'
import Camera from './a-camera-modal.vue'

import { presentToast } from '@/plugins/w/ionic-toast/index'
import { defineComponent } from 'vue'

import { camera } from 'ionicons/icons'

import { mapGetters } from 'vuex'

export default defineComponent({
	props: {
		module: { type: String, default: 'catalogue' },
		title: { type: String, default: 'Search' },
		canUseBiometry: { type: Boolean, default: false }
	},
	emits: ['filterActived'],
	data() {
		return {
			filter: '',
			metaFrom: null,
			metaTill: null,
			activeFilter: false,
			image: '',
			searchActive: false,
			biometricActive: false,
			camera //icon
		}
	},

	watch: {
		filter: function () {
			if (this.filter.length == 0) {
				this.$store.commit(`${this.module}/filterSearchUpdated`, '')
				this.$store.dispatch(`${this.module}/fetchByPage`, 1)
				this.$emit('filterActived', { active: false })
			} else {
				this.$store.commit(
					`${this.module}/filterSearchUpdated`,
					this.filter
				)
				this.$store.dispatch(`${this.module}/fetchByPage`, 1)
				this.$emit('filterActived', { active: true })
			}
		}
	},

	computed: {
		...mapGetters('catalogue', ['pins']),
		totalPins() {
			return this.$store.getters[this.module + '/totalPinsCount']
		},
		totalFilteredPins() {
			return this.$store.getters[this.module + '/totalPinsByFilter']
		}
	},

	methods: {
		async openFilter() {
			this.filter = ''
			const modal = await modalController.create({
				component: Filter,
				componentProps: { module: this.module },
				cssClass: 'modal-fullheight'
			})
			modal.onDidDismiss().then((modalData) => {
				if (modalData.data) {
					this.metaFrom = modalData.data.metaFrom
					this.metaTill = modalData.data.metaTill
				}
				this.activeFilter = this._isFilterActived()
			})
			return modal.present()
		},

    async openImageSelect() {
      const gallery = async () => { await this.openCamera('gallery') }
      const openCamera = async () => { await this.openCamera('camera') }
      const sheet = await actionSheetController.create({
        buttons: [
          {
            text: "Take a photo",
            async handler() {
              openCamera()
              await actionSheetController.dismiss()
            },
          },
          {
            text: "Pick from gallery",
            async handler() {
              gallery()
              await actionSheetController.dismiss()
            },
          },
          {
            text: "Cancel",
            role: "destructive",
          }
        ]
      })
      await sheet.present()
    },


    async openCamera(source = 'camera') {
			try {
				const modal = await modalController.create({
					component: Camera,
					cssClass: 'modal-fullheight',
          componentProps: { source: source }
				})
				await modal.present()
				const { data } = await modal.onWillDismiss()
				if (data?.text === 'cancel') return
				this.image = data.result
				console.log()
				this.biometricActive = true
				await this.$store.dispatch(
					`${this.module}/changeBiometricState`,
					{ image: this.image, isActive: this.biometricActive }
				)
				await this.$store.dispatch(
					`${this.module}/biometricSearchResults`
				)
			} catch (err) {
				console.log(err)
				if (err?.message == 'User cancelled photos app') return
				presentToast("Couldn't take a picture", 'danger')
			}
		},

    cancelBiometricSearch() {
			this.$store.dispatch(`${this.module}/unFetchBiometricResults`)
			this.image = ''
			this.biometricActive = false
			this.$store.dispatch(`${this.module}/changeBiometricState`, {
				image: this.image,
				isActive: this.biometricActive
			})
		},

		_isFilterActived() {
			let filter = this.$store.state[this.module].filter
			if (
				filter.official != false ||
				(filter.event && filter.event.id) ||
				(filter.country && filter.country.id) ||
				(filter.since &&
					this.metaFrom &&
					filter.since != this.metaFrom) ||
				(filter.till &&
					this.metaTill &&
					filter.till != this.metaTill) ||
				filter.since != null ||
				filter.till != null
			) {
				this.$emit('filterActived', { active: true })
				return true
			}
			this.$emit('filterActived', { active: false })
			return false
		}
	}
})
