import loginGuardIsLoggedIn from '@/plugins/w/loginGuard'

export default (title, path, component, requiresAuth = false) => {
  return {
    path,
    meta: { title, requiresAuth: requiresAuth },
    component: component,
    beforeEnter: loginGuardIsLoggedIn
  }
}
