
import wAxios from '@/plugins/w/axios'
import { defineComponent } from 'vue'
import {
	closeOutline,
	caretForwardOutline,
	caretDownOutline
} from 'ionicons/icons'
import { modalController, pickerController } from '@ionic/vue'
import SelectionModal from '@/plugins/app/_components/z-selection-modal.vue'

const api = {
	meta: () => wAxios.get('v1/pins/meta')
}

export default defineComponent({
	props: {
		module: { type: String, default: 'catalogue' }
	},

	mounted() {
		this._loadFilterMetadata()
	},

	data() {
		return {
			filterMetadata: {},
			closeOutline,
			caretForwardOutline,
			caretDownOutline
		}
	},

	computed: {
		totalPinsByFilter() {
			return this.$store.getters[this.module + '/totalPinsByFilter']
		},

		pins() {
			return this.$store.state[this.module].pins
		},

		filter: {
			get() {
				return this.$store.state[this.module].filter
			},
			set(val) {
				this.$store.commit(`${this.module}/filterUpdated`, val)
			}
		},

		years() {
			if (!this.filterMetadata.year) return

			// get list of possible years
			const years = []
			for (
				let i = this.filterMetadata.year.since;
				i <= this.filterMetadata.year.till;
				i++
			) {
				years.push(i)
			}
			return years
		}
	},

	watch: {
		filter: {
			deep: true,
			handler() {
				// filter results on every filter parameters change
				this.$store.commit(`${this.module}/page`, 1)
				this.$store.commit(`${this.module}/allDataLoadedChanged`, false)
				this.$store.dispatch(`${this.module}/fetchByPage`, 1)
			}
		}
	},

	methods: {
		closeModal() {
			modalController.dismiss({
				metaFrom: this.filterMetadata.year.since,
				metaTill: this.filterMetadata.year.till
			})
		},

		openEventSelectionModal() {
			this._openSelectionModal(
				'Purpose of issue',
				['All', ...this.filterMetadata.events],
				this.filter.event,
				(data) => {
					if (data.data)
						this.filter.event = data.data == 'All' ? '' : data.data
				},
				'All'
			)
		},

		openCountrySelectionModal() {
			this._openSelectionModal(
				'Country',
				[{ title: 'All', id: null }, ...this.filterMetadata.countries],
				this.filter.country,
				(data) => {
					if (data.data) this.filter.country = data.data
				},
				'All'
			)
		},

		openYearSinceSelectionModal() {
			const options = this.years.map((year) => {
				return { id: year, title: year.toString() }
			})
			this._openSelectionModal(
				'Year since',
				[{ title: 'Any', id: null }, ...options],
				this.filter.since,
				(data) => {
					if (data.data) this.filter.since = data.data.id
				},
				'Any'
			)
		},

		openYearTillSelectionModal() {
			const options = this.years.map((year) => {
				return { id: year, title: year.toString() }
			})
			this._openSelectionModal(
				'Year till',
				[{ title: 'Now', id: null }, ...options],
				this.filter.till,
				(data) => {
					if (data.data) this.filter.till = data.data.id
				},
				'Now'
			)
		},

		onRemoveAllFilters() {
			this.filter = {
				official: false,
				event: null,
				country: null,
				since: null,
				till: null
			}
			this.$store.dispatch(`${this.module}/removeFilter`)
		},

		async _openSelectionModal(
			title,
			list,
			selectedItem,
			completionCallback,
			firstTitle
		) {
			const selectionModal = await modalController.create({
				component: SelectionModal,
				componentProps: { list, title, selectedItem, firstTitle },
				cssClass: 'modal-fullheight'
			})
			selectionModal.onDidDismiss().then(completionCallback)
			return selectionModal.present()
		},

		async _loadFilterMetadata() {
			try {
				const { filters } = await api.meta()
				this.filterMetadata = filters
			} catch (err) {
				console.error(err)
			}
		},

		_isEmpty(obj) {
			return Object.keys(obj).length == 0
		}
	}
})
