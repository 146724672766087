import { createStore } from 'vuex'
import catalogueModule from './plugins/app/_db/catalogue'
import collectionModule from '@/plugins/app@user/_db/collection'
import countriesModule from './plugins/app/_db/countries'
import wAuth from '@/plugins/w/authStore/wAuth.store'

export default createStore({
	modules: {
		wAuth,
		catalogue: catalogueModule,
		collection: collectionModule,
		countries: countriesModule
	}
})
