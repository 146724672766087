import { createRouter, createWebHashHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'
import wRoute from '@/plugins/w/tip/w-route'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    component: () => import('@/plugins/app/welcome/welcome.vue'),
  },
  {
    path: '/app',
    component: () => import('@/plugins/app/_layout/tabs-menu.layout.vue'),
    children: [
      //  public
      wRoute('Katalóg',        'catalogue',          () => import('@/plugins/app/catalogue/catalogue.vue')),
      wRoute('Zoznam krajín',  'country-list',       () => import('@/plugins/app/country-list/pin-group-country-list.vue')),
      wRoute('Pin detail',     'pin-detail/:id',     () => import('@/plugins/app/pin-details/pin-details.vue')),
      wRoute('Country detail', 'country-detail/:id', () => import('@/plugins/app/country-list/catalogue-pin-group.vue')),
      wRoute('About us',       'intro',              () => import('@/plugins/app/welcome/intro.vue')),

      //  account
      wRoute('Registrácia',    'register',     () => import('@/plugins/w@screen/register/register.vue')),
      wRoute('Prihlásenie',    'login',        () => import('@/plugins/w@screen/login/login.vue')),
      wRoute('Zabudnuté heslo','forgot',       () => import('@/plugins/w@screen/forgot/forgot.vue')),
      wRoute('Reset hesla',    'reset',        () => import('@/plugins/w@screen/new-password/new-password.vue')),

      // account required
      wRoute('Kolekcia',       'my-collection',   () => import('@/plugins/app@user/collection/my-collection.vue'), true),
      wRoute('Profil',         'profile',         () => import('@/plugins/w@screen/profile/profile.vue'), true),
      wRoute('Manage profile', 'manage-profile',  () => import('@/plugins/w@screen/profile/manage-profile.vue'), true),
      wRoute('Delete profile', 'delete-profile',  () => import('@/plugins/w@screen/profile/delete-profile.vue'), true),
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
