import wAxios from '@/plugins/w/axios'

export default {
  namespaced: true,
  state() {
    return {
      pins: [],
      totalPinsCount: 0,
      totalPinsByFilter: 0,
      page: 1,
      allDataLoaded: false,
      isLoading: false,
      filter: {
        search: '',
        official: false,
        event: null,
        country: null,
        since: null,
        till: null
      },
      biometricSearch: {
        image: '',
        isActive: false
      }
    }
  },
  getters: {
    totalPinsCount(state) {
      return state.totalPinsCount
    },
    totalPinsByFilter(state) {
      return state.totalPinsByFilter
    },
    pins(state) {
      return state.pins
    },
    isLoading(state) {
      return state.isLoading
    },
    allDataLoaded(state) {
      return state.allDataLoaded
    },
    filters(state) {
      return {
        ...state.filter,
        country: state.filter.country?.id,
        event: state.filter.event?.id,
        official: state.filter.official ? 1 : null,
        'year[since]': state.filter.since,
        'year[till]': state.filter.till
      }
    },
    biometricSearch(state) {
      return state.biometricSearch
    }
  },
  mutations: {
    fetched(state, pins) {
      state.pins = pins
    },
    fetchedByPage(state, pins) {
      state.pins = state.pins.concat(pins)
    },
    pageIncremented(state) {
      state.page = ++state.page
    },
    page(state, page) {
      state.page = page
    },
    allDataLoadedChanged(state, data) {
      state.allDataLoaded = data
    },
    loadingChanged(state, status) {
      state.isLoading = status
    },
    filterUpdated(state, filterData) {
      state.filter = { ...this.state.filter, ...filterData }
    },
    filterSearchUpdated(state, search) {
      state.filter.search = search
    },
    removeFilter(state, data) {
      state.filter = data
    },
    totalPinsCount(state, data) {
      state.totalPinsCount = data
    },
    totalPinsByFilter(state, data) {
      state.totalPinsByFilter = data
    },
    inCollectionChanged(state, data) {
      let pin = state.pins.find(pin => pin.id == data.pinId)
      if (pin) {
        pin['in_collection'] = data.value
      }
    },
    changeBiometricInfo(state, data) {
      state.biometricSearch.image = data.image
      state.biometricSearch.isActive = data.isActive
    }
  },

  actions: {
    async fetchPins({ getters, commit }) {
      try {
        const data = await wAxios.get_auth('v1/pins', {
          params: getters.filters
        })
        commit('fetched', data)
      } catch (err) {
        console.log(err)
      }
    },
    async fetchByPage({ getters, commit, state }, page?) {
      commit('loadingChanged', true)
      if ( page ) {
        commit('page', page)
        commit('fetched', [])
        commit('allDataLoadedChanged', false)
      }
      try {
          const message = `LOG:catalogue|${state.page}`
          try {
              await wAxios.post(`v1/logger`, {message: message})
          } catch (e) {
            console.log('ERROR')
          }
          const data = await wAxios.get_auth_all(`v1/pins?page=${state.page}&per_page=40`, {
          params: getters.filters
        }).then(response => {
          if (!state.totalPinsCount) {
            commit('totalPinsCount', response.data.meta.total)
          }

          commit('totalPinsByFilter', response.data.meta.total)
          return response.data.data
        })

        commit('fetchedByPage', data)
        commit('pageIncremented')
        commit('loadingChanged', false)
        if (data.length == 0) {
              commit('allDataLoadedChanged', true)
          }
      } catch (err) {
          console.log(err)
      }
   },
   removeFilter({commit}) {
    const filter = {
      search: '',
      official: false,
      event: null,
      country: null,
      since: null,
      till: null
    }
    commit('removeFilter', filter)
    commit('totalPinsByFilter', 0)
   },
   changeBiometricState({commit }, value) {
      commit('changeBiometricInfo', value)
   },

   blobToFile({state}) {
      return (fetch(state.biometricSearch.image)
        .then(function(res){return res.arrayBuffer()})
        .then(function(buf){return new File([buf], 'image.jpeg',{type: 'image/jpeg'})})
      )
   },

   async biometricSearchResults({dispatch, commit}) {
    const forData = new FormData()
    forData.append('image', await dispatch('blobToFile') )

    commit('loadingChanged', true)
    commit('fetched', [])

    try {
      const response = await wAxios.post_auth('v1/bio', forData)
      commit('fetched', response.data.response)
      commit('totalPinsCount', response.data.response.length)
    } catch (err) {
      console.log(err)
    }
    commit('loadingChanged', false)
    commit('allDataLoadedChanged', true)
   },

   async unFetchBiometricResults({dispatch, commit}) {
    commit('loadingChanged', true)
    commit('fetched', [])

    commit('page', 1)
    commit('totalPinsCount', 0)
    await dispatch('fetchByPage')
    
    commit('allDataLoadedChanged', false)
    commit('loadingChanged', false)
   }
  },
}
