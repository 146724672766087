<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button icon-only @click="closeModal">
            <ion-icon :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-no-padding">
      <ion-searchbar :value="query" @ion-change="query = $event.detail.value" />
      <ion-radio-group :value="selectedItem?.id || selectedItem" allow-empty-selection>
        <ion-item v-for="(item, i) in filteredList" :key="i" class="ion-no-padding" @click="onSelect(item)">
          <ion-label class="d-flex ion-align-items-center ion-padding-start ion-padding-vertical ion-no-margin">
            <img 
              v-if="item.image"
              class="ion-margin-end"
              :style="{ width: item.imageWidth }"
              :src="item.image"
            />
            {{ item.title || item }}
          </ion-label>
          <ion-radio slot="end" :value="item.id || item"></ion-radio>
        </ion-item>
      </ion-radio-group>
      <div 
        v-if="!filteredList.length"
        class="ion-padding ion-text-center"
      >No results for given query.</div>
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue'
import { modalController } from '@ionic/vue'
import { arrowBack } from 'ionicons/icons'
import {
    IonRadio,
    IonRadioGroup
  } from '@ionic/vue'

export default defineComponent({
  components: {
    IonRadio,
    IonRadioGroup
  },

  props: {
    list: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    firstTitle: {
      type: String,
      required: true
    },
    selectedItem: {
      type: [Object, String]
    }
  },

  data() {
    return {
      query: '',
      arrowBack
    }
  },

  computed: {
    filteredList() {
      let list = this.list.filter(item => (item.title || item).toLowerCase().includes(this.query.toLowerCase())).filter(item => item.id)
      list = list.sort((a,b) => a.title < b.title ? -1 : 1 )
      list.unshift({title: this.firstTitle, id: null})
      return list
    }
  },

  methods: {
    onSelect(item) {
      modalController.dismiss(item)
    },

    closeModal() {
      modalController.dismiss()
    }
  },
})
</script>

<style lang="sass" scoped>
ion-toolbar
  --border-width: 0 !important

ion-label
  font-size: 20px
  font-weight: 700
  --ion-padding: 20px
</style>
