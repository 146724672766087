import axios from 'axios'
import store from '@/store'

let isRefreshing = false
let refreshHook = Promise.resolve(null)

async function _unauthorised(error) {
    if (error.response.status == 422) {
        return Promise.reject(error)
    }

    if (error.response.data.error == 'A user was not found with the given credentials.') {
        return Promise.reject(error.response.data.error)
    }

    if (error.response.data.error.includes('has been suspended')) {
        return Promise.reject('This account has been suspended for 15 minutes due to many login attempts, please try again later.')
    }

    if (error.response.data.error.includes('A user was found to match all plain text')) {
        return Promise.reject(error.response.data.error)
    }

    if(!error.response || error.response.status != 401) {
        return Promise.reject(error)
    }

    if(!isRefreshing) {
        refreshHook = new Promise((resolve, reject) => {
            store.dispatch('wAuth/refreshToken')
                .then((token) => resolve(token))
                .catch((error) => reject(error))
                .finally(() => isRefreshing = false)
        })

        isRefreshing = true
    }

    try {
        const token = await refreshHook
        if (token) {
            error.config.headers = {
                'Authorization': `bearer ${token}`
            }
            return Promise.resolve(await axios.request(error.config))
        }
        return Promise.reject('No token')
    } catch (error) {
        console.log(error)
    }
}

axios.interceptors.response.use(
    (response) => response,
    (err) => _unauthorised(err)
)
