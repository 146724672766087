import wAxios from '@/plugins/w/axios'
import './refreshExpired'

export default {
    namespaced: true,
    state: {
        isLoading: false,
        status: '',
        rememberMe: true,
        _token: localStorage.getItem('wzo_vue_user_token') || '',
        user: JSON.parse(localStorage.getItem('wzo_vue_user_user') || '{}'),
    },
    mutations: {
        auth_request(state: any) {
            state.status = 'loading'
        },
        auth_success(state: any, data: any) {
            state.status = 'success'
            if (data.token) {
                state._token = data.token
                if (state.rememberMe) {
                    localStorage.setItem('wzo_vue_user_token', data.token)
                }
            }

            if (data.user) {
                state.user = data.user
                if (state.rememberMe) {
                    localStorage.setItem('wzo_vue_user_user', JSON.stringify(data.user))
                }
            }
        },
        auth_error(state: any) {
            state.status = 'error'
            state._token = ''
            localStorage.removeItem('wzo_vue_user_token')
            localStorage.removeItem('wzo_vue_user_user')
        },
        logout(state: any) {
            state.status = ''
            state._token = ''
            state.user = {}
            localStorage.removeItem('wzo_vue_user_token')
            localStorage.removeItem('wzo_vue_user_user')
        },
        remember(state: any, data) {
            state.rememberMe = data
        },
        loadingChanged(state, data) {
            state.isLoading = data
        }
    },
    actions: {
        login({commit}: {commit: any}, credentials: any) {
            commit('loadingChanged', true)
            return new Promise((resolve, reject) => {
                commit('auth_request')

                wAxios.post_data('v1/auth/login', credentials)
                    .then((response: any) => {
                        const token = response.data.response.token
                        const user = response.data.response.user
                        commit('loadingChanged', false)

                        commit('auth_success', {
                            token: token,
                            user: user
                        })

                        resolve(response)
                    }).catch(error => {
                        commit('auth_error')
                        commit('loadingChanged', false)
                        reject(error)
                    })
            })
        },
        signup({commit }: {commit: any}, credentials: any) {
            commit('loadingChanged', true)

            return new Promise((resolve, reject) => {
                commit('auth_request')

                wAxios.post_data('v1/auth/signup', credentials)
                    .then((response: any) => {
                        const token = response.data.response.token
                        const user = response.data.response.user
                        commit('loadingChanged', false)
                        commit('auth_success', {
                            token: token,
                            user: user
                        })

                        resolve(response)
                    }).catch(error => {
                        commit('auth_error')
                        commit('loadingChanged', false)
                        reject(error)
                    })
            })
        },
        logout({commit}: {commit: any}) {
            commit('logout')
        },
        forgotPassword({commit}: {commit: any}, credentials: any) {
            commit('loadingChanged', true)

            return new Promise((resolve, reject) => {
                wAxios.post_auth_data('v1/auth/forgot', credentials)
                    .then(response => {
                        commit('loadingChanged', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('loadingChanged', false)
                        reject(error)
                    })
            })
        },
        resetPassword({commit}: {commit: any}, credentials: any) {
            commit('loadingChanged', true)
            return new Promise((resolve, reject) => {
                wAxios.post_auth_data('v1/auth/reset', credentials)
                    .then(response => {
                        commit('loadingChanged', false)
                        resolve(response)
                    })
                    .catch(error => {
                        commit('loadingChanged', false)
                        reject(error)
                    })
            })
        },
        async refreshToken({commit}: {commit: any}) {
            try {
                const response: any = await wAxios.post_auth_data('v1/auth/refresh')

                const token = response.data.response.token
                const user = response.data.response.user

                commit('auth_success', { token, user })

                return token
            } catch (error) {
                commit('auth_error')
                console.error(error)
                if (error.response.status == 500) {
                    commit('logout')
                }
            }
        },
        async userInfo({commit}: {commit: any}) {
            return new Promise((resolve, reject) => {
                wAxios.get_auth_data('v1/auth/info')
                    .then((response: any) => {
                        const user = response.user

                        commit('auth_success', {
                            user: user
                        })

                        resolve(user)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        updateUserInfo({commit}: {commit: any}, data: any) {
            return new Promise((resolve, reject) => {
                wAxios.post_auth_data('v1/auth/info', data)
                    .then((response: any) => {
                        const user = response.user

                        commit('auth_success', {
                            user: user
                        })

                        resolve(user)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
    },
    getters: {
        isLoggedIn: (state: any) =>  {
            return !!state._token
        },
        isLoading: (state: any) =>  {
            return state.isLoading
        },
        authStatus: (state: any) => {
            return state.status
        },
        user: (state: any) => {
            return state.user
        },
        token: (state: any) => {
            return state._token

        }
    }
}
