
import { IonApp } from '@ionic/vue'
// import { Plugins, StatusBarStyle } from '@capacitor/core'
// const { SplashScreen, StatusBar } = Plugins
import { isPlatform } from '@ionic/vue'
import { MobileAccessibility } from '@ionic-native/mobile-accessibility'
import wAxios from '@/plugins/w/axios'

export default {
	components: {
		IonApp
	},
  watch: {
    async $route(to, from) {
      const message = `LOG:from:${from.fullPath}|to:${to.fullPath}`
      try {
        await wAxios.post(`v1/logger`, {message: message})
      } catch (e) {
        console.log('ERROR')
      }
    },
  },
	created() {
		MobileAccessibility.setTextZoom(100)
		MobileAccessibility.updateTextZoom()
		MobileAccessibility.usePreferredTextZoom(false)

		if (isPlatform('desktop')) return

		// StatusBar.setStyle({
		// 	style: StatusBarStyle.Light
		// })
    //
		// StatusBar.setBackgroundColor({
		// 	color: '#ffffff'
		// })
		// SplashScreen.hide()
	}
}
