import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_cropper = _resolveComponent("cropper")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal('cancel')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        color: "light",
                        icon: _ctx.close
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Select pin in the cutout")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        fullscreen: "",
        "no-bounce": ""
      }, {
        default: _withCtx(() => [
          (_ctx.resultImageBlob)
            ? (_openBlock(), _createBlock(_component_cropper, {
                key: 0,
                ref: "cropper",
                imageRestriction: "fill-area",
                defaultBoundaries: _ctx.imageBounds,
                minHeight: 200,
                minWidth: 200,
                class: "foto-preview",
                src: _ctx.resultImageBlob
              }, null, 8, ["defaultBoundaries", "src"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { class: "shoot-wraper" }, {
                default: _withCtx(() => [
                  (_ctx.isEditing)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        class: "editting",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cameraInit()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            size: "large",
                            color: "danger",
                            icon: _ctx.source == 'camera' ? _ctx.cameraOutline : _ctx.imageOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isEditing)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        class: "editting",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeModal()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            size: "large",
                            color: "success",
                            icon: _ctx.checkmark.replace('Checkmark', 'Save')
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}