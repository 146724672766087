/*
HOW TO:
    import wAxios from '@/plugins/w/axios'
    wAxios.get
    wAxios.post
    wAxios.get_auth
    wAxios.post_auth
*/

import axios from 'axios'
import store from '@/store'

axios.defaults.baseURL = process.env.VUE_APP_WAXIOS_URL


export default {
    
    async get(url, config?) {    
        return axios.get(url, config)
            .then(response => response.data.data)
            .catch(error => Promise.reject(error.response))
    },

    async get_all(url, config?) {
        return axios.get(url, config)
            .catch(error => Promise.reject(error.response))
    },

    async get_data(url, config?) {    
        return axios.get(url, config)
    },

    async post(url, params = null, config?) {
        return this.post_data(url, params, config)
            .catch(error => Promise.reject(error.response))
    },

    async post_data(url, params = null, config?) {
        return axios.post(url, params, config)
            .then(response => response)
            .catch(error => Promise.reject(error.response))
    },

    async put(url, params = null, config?) {
        return axios.put(url, params, config)
    },

    async get_auth(url, config?) {
        return this.get(url, this._config(config))
    },

    async get_auth_all(url, config?) {
        return this.get_all(url, this._config(config))
    },

    async get_auth_data(url, config?) {
        config = this._config(config)

        return axios.get(url, config)
            .then(response => response.data.data)
            .catch(error => Promise.reject(error.response))
    },

    async put_auth(url, params=null, config?) {
        return this.put(url, params, this._config(config))
    },

    async post_auth(url, params=null, config?) {
        return this.post(url, params, this._config(config))
    },

    async post_auth_data(url, params=null, config?) {
        return this.post_data(url, params, this._config(config))
    },

    async delete_auth(url, config?) {
        return axios.delete(url, this._config(config))
            .then(response => response.data.data)
            .catch(error => Promise.reject(error.response))
    },

    //////////////////////////////////////////////////////////////////////////////
    // helpers

    _config(config?) {
        return {
            ...config,
            headers: {
                Authorization: `Bearer ${store.getters['wAuth/token']}`
            }
        }
    }
}
